.card {
  border: unset;
  &-body {
    padding: 1rem 2rem 1rem;
    position: relative;
  }
  &-contest-horizontal {
    background: #fff;
    border-radius: 1rem;
    .card-img-top {
      border-radius: 1rem 0 0 1rem;
      height: 100%;
    }
    .popover {
      max-width: 95%;
    }
  }
  &-title {
    font-size: 1rem;
    line-height: 1.6rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  &-sub-title {
    font-size: 0.8rem;
    line-height: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  &-text {
    font-size: 0.7rem;
    line-height: 0.9rem;
    margin-bottom: 0.6rem;
    color: $secondary;
    font-weight: normal;
  }
  &-tiles {
    .btn {
      &-link {
        color: $dark;
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
      }
    }
    .card {
      &-body {
        padding: 1rem 0 0 0;
      }
      &-title {
        font-size: 1rem;
        line-height: 1.4rem;
        margin-bottom: 0.3rem;
      }
      &-text {
        font-size: 1rem;
        line-height: 1.4rem;
        color: $secondary;
      }
    }
  }
  &-seeall {
    height: 100%;
    background-color: $light;
    border-radius: 0.7rem;
    overflow: hidden;
    .img {
      &-card,
      &-block {
        border-radius: 0;
      }
    }
    .card {
      &-body {
        padding: 1.6rem 1.2rem;
      }
      &-img {
        border-radius: 0;
      }
      &-title {
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: #484848;
        font-weight: 500;
        margin-bottom: 0;
        // min-height:9rem;
        span {
          display: block;
          font-size: 1rem;
          line-height: 1.4rem;
          color: #484848;
          font-weight: 400;
          margin-top: 0.8rem;
        }
      }
      &-text {
        font-size: 1rem;
        line-height: 1.4rem;
        color: #484848;
      }
      &-new-badge {
        width: 4.6rem;
      }
    }
    .list-actions {
      margin: 1.2rem;
    }
  }
  &-horizontal {
    background-color: transparent;
    border-radius: unset;
  }
  &-lg {
    .card {
      &-title {
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-bottom: 0.8rem;
        font-weight: 500;
      }
      &-text {
        font-size: 1.2rem;
        line-height: 2rem;
        margin-bottom: 1rem;
        color: $dark;
      }
    }
  }
  &-top-img-wrapper {
    position: relative;
  }
  &-disabled {
    opacity: 0.5;
  }
  &-disabled-not-btn {
    .car-top-img-wrapper,
    .card-title,
    .card-text,
    .points-block {
      opacity: 0.5;
    }
  }
  &-reward {
    .points-box {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
    .icon-info {
      position: absolute;
      right: 2px;
      top: 0.5rem;
      padding-right: 0.3rem;
    }
  }
  &-time-stamp {
    font-size: 0.8rem;
  }
  &-new-badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 4.3rem !important;
  }
}

// Image blocks & cards
.full-video {
  .react-player {
    padding-top: 43.5%;
    position: relative;
  }
  .react-player > div,
  .react-player > video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: auto !important;
  }
}
.img {
  &-block {
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    &::before {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    &-overlay {
      &::before {
        content: "";
        background-color: $dark;
        opacity: 0.7;
      }
    }
    &-gradient {
      &::before {
        content: "";
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
    &-sm {
      border-radius: 0.8rem;
    }
  }

  &-card {
    border-radius: 1rem;
    width: 100%;
    &-full-img {
      border-radius: 0;
    }
    &-sm {
      border-radius: 0.8rem;
    }
  }
}

// Social purpose icons
.icon {
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  &-block {
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    justify-content: space-between;
    .icon {
      color: $light;
      font-size: 1rem;
      &.active {
        color: $danger;
        .icon-num {
          color: $light;
        }
      }
      display: flex;
      align-items: center;
    }
    &-lg {
      .icon {
        &-play {
          font-size: 6.3rem;
        }
      }
    }
    &-top {
      bottom: unset;
      top: 1rem;
    }
  }
  &-num {
    font-family: "TCCC-UnityText" !important;
    font-weight: normal !important;
    display: inline-block;
    padding-left: 0.2rem;
  }
  &-dark {
    .icon {
      color: #000;
      &.active {
        .icon-num {
          color: #000;
        }
      }
    }
  }
  &-play,
  &-Lock {
    color: $light;
    font-size: 8rem;
    width: 4rem !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &-Lock {
    width: unset;
    font-size: 2.5rem;
    &:hover {
      color: $light;
    }
  }
  &-back,
  &-close {
    font-size: 2rem;
    cursor: pointer;
  }
  &-notifi {
    font-size: 3rem;
    font-weight: 500;
    color: $dark;
    &:hover {
      color: $dark;
    }
  }
  &-info {
    font-size: 1.6rem;
  }
}
.promo {
  &-code {
    display: inline-flex;
    align-items: center;
    border: 1px dashed #000;
    border-radius: 0.5rem;
    .card-text {
      font-size: 2rem;
      font-weight: 500;
      color: $dark;
    }
  }
  &-icon {
    max-width: 3rem;
    position: relative;
    right: -1px;
    top: -1px;
    bottom: -1px;
    margin-bottom: -2px;
  }
}
@media (max-width: 590px) {
  .promo {
    &-code {
      .card-text {
        font-size: 2.8vw;
      }
    }
    &-icon {
      min-width: 2rem;
      max-width: 2rem;
    }
  }
}
@media (min-width: 590px) {
  .card {
    &-seeall {
      .card {
        &-body {
          min-height: 9rem;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .card {
    &-body {
      padding: 2rem 3rem;
      min-height: 7rem;
    }

    &-title {
      font-size: 2rem;
      line-height: 2.8rem;
      margin-bottom: 0.6rem;
      font-weight: 500;
    }
    &-sub-title {
      font-size: 1.2rem;
      line-height: 1.4rem;
      max-width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
    &-text {
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin-bottom: 1.2rem;
    }
    &-tiles {
      .btn {
        &-link {
          font-size: 1.6rem;
        }
      }
      .card {
        &-body {
          padding: 1.8rem 0 0 0;
        }
        &-title {
          font-size: 2rem;
          line-height: 1;
          margin-bottom: 0.6rem;
        }
        &-text {
          font-size: 1.6rem;
          line-height: 1;
        }
      }
    }
    &-horizontal {
      &-full-img {
        border-radius: 2rem;
      }
    }
    &-lg {
      .card {
        &-title {
          font-size: 2.8rem;
          line-height: 3.2rem;
          margin-bottom: 1.8rem;
        }
        &-text {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }
      }
    }
    &-contest-horizontal {
      border-radius: 20px;
      .card-img-top {
        border-radius: 20px 0 0 20px;
      }
      .popover {
        max-width: 80%;
      }
    }
    &-xl {
      .card {
        &-title {
          font-size: 3.2rem;
          line-height: 4rem;
          margin-bottom: 0.8rem;
        }
        &-text {
          font-size: 1.6rem;
          line-height: 1.9rem;
        }
      }
      .btn {
        font-size: 2rem;
        line-height: 1;
        padding: 2rem 4rem;
        &-claim {
          font-size: 2.5rem;
          .icon {
            width: 2.5rem;
          }
        }
      }
    }
    &-reward {
      .points-box {
        right: 1.3rem;
        top: 1.6rem;
      }
      .icon-info {
        right: 0.5rem;
        top: 1rem;
        padding-right: 0.5rem;
        font-size: 1.8rem;
      }
    }
    &-time-stamp {
      font-size: 1.6rem;
    }
    &-seeall {
      .card {
        &-body {
          padding: 1.4rem 1.8rem;
          // min-height: 9rem;
        }
        &-title {
          font-size: 1.4rem;
          line-height: 1.8rem;
          span {
            font-size: 1.2rem;
            line-height: 1.6rem;
          }
        }
        &-text {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }
      .list-actions {
        margin: 1.3rem 1.6rem;
      }
    }
    &-new-badge {
      width: 8.9rem !important;
    }
  }
  .rounded-lg {
    .card-reward {
      border-radius: 2rem;
    }
    .card-img-top {
      border-radius: 2rem 2rem 0 0;
    }
  }
  // Image blocks & cards
  .img {
    &-block {
      border-radius: 2rem;
      &-sm {
        border-radius: 1.5rem;
      }
    }
    &-card {
      border-radius: 2rem;
      &-full-img {
        border-radius: 0;
      }
      &-sm {
        border-radius: 1.5rem;
      }
    }
  }

  // Social purpose icons
  .icon {
    &-block {
      left: 1.5rem;
      right: 1.5rem;
      bottom: 1.7rem;
      .icon {
        font-size: 1.5rem;
        &.active {
          color: $danger;
          .icon-num {
            color: $light;
          }
        }
      }
      &-lg {
        left: 5rem;
        right: 5rem;
        bottom: 2rem;
        .icon {
          font-size: 3.7rem;
          &-num {
            font-size: 3rem;
            padding-left: 0.5rem;
          }
          &-play {
            font-size: 8rem;
          }
        }
      }
      &-top {
        bottom: unset;
        top: 2rem;
      }
    }
    &-play {
      font-size: 8rem;
      width: 8rem !important;
    }
    &-Lock {
      font-size: 6rem;
    }
    &-num {
      font-size: 1.2rem;
    }
    &-dark {
      .icon {
        &.active {
          .icon-num {
            color: #000;
          }
        }
      }
    }
    &-like {
      font-size: 3rem;
      &.active {
        color: $danger;
      }
    }
  }
  .promo {
    &-code {
      border: 2px dashed #000;
      border-radius: 1rem;
    }
    &-icon {
      min-width: 4rem;
      right: -2px;
      top: -2px;
      bottom: -2px;
      margin-bottom: -4px;
    }
  }
}

@media (min-width: 992px) {
  .card {
    &-seeall {
      .card {
        &-body {
          min-height: 7rem;
        }
      }
    }
  }
  .img {
    &-card {
      &-full-img {
        border-radius: 2rem;
      }
    }
  }
}
