.hi_global {
  .s {
    &-title {
      line-height: 2.5rem;
    }
  }
}

@media (min-width: 768px) {
  .hi_global {
    .s {
      &-title {
        line-height: 3.2rem;
      }
    }
  }
}
