.accordion {
  &-item {
    padding: 2rem 0;
    border-bottom: 1px solid $grey-2;
  }
  &-body {
    padding-top: 1rem;
    font-size: 1.2rem;
    line-height: 2.2rem;
    p {
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ol {
      list-style: decimal;
      padding: 1rem 0 1rem 2.5rem;
      margin: 0;
      li {
        list-style: decimal;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
    dl {
      margin-bottom: 0;
      dt {
        padding: 0.5rem 0;
      }
      dd {
        padding-left: 2rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
  }
  &-header {
    position: relative;
    padding-right: 3rem;
  }
  &-button {
    border: none;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 500;
    padding: 0;
    background: transparent;
    width: 100%;
    text-align: left;
    color: $dark;
    &:focus-visible {
      outline: unset;
    }
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url('~/public/assets/img/icons/minus.svg') center center;
      width: 2.5rem;
      height: 2.5rem;
    }
    &.collapsed {
      &::after {
        background-image: url('~/public/assets/img/icons/plus.svg');
      }
    }

    &:focus {
      border: unset;
    }
  }
  &2{
    .accordion{
      &-item{border: unset;}
      &-button{
        padding-top: 1.6rem; padding-bottom: 1.6rem;
        &::after{
          right: 1.5rem;
          width: 1.5rem;
          height: 1.5rem;
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-image: url('~/public/assets/img/icons/up-arrow.svg');
        }
        &.collapsed {
          &::after {
            background-image: url('~/public/assets/img/icons/down-arrow.svg');
          }
        }
      }
    }
  }
  @media (min-width: 767.98px) {
    &-item {
      padding: 3rem 0;
    }
    &-body {
      padding-top: 1rem;
      font-size: 1.6rem;
      p {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
      ol {
        padding-left: 3rem;
        li {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }
      dl {
        dt {
          h4 {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }
        dd {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }
    }
    &-button {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    &-content {
      font-size: 1.6rem;
    }
    &2{
    .accordion{
      &-button{font-size:2.8rem; padding-top: 3rem; padding-bottom: 3rem;
        &::after{width: 2.5rem;
          height: 2.5rem;}
      
      }
      &-body{padding-top: 2.5rem;}
    }
    }
  }
}
