.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.popup {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-btn {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.option-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.quiz-container label input[type="radio"] {
  display: none;
}

.quiz-container label span {
  font-size: 1.2rem;
  display: inline-block;
  transition: color 0.5s ease;
}
.quiz-container label {
  display: block;
  cursor: pointer;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
  margin-top: 10px;
}
.quiz-container label .circle {
  height: 1.2rem;
  width: 1.2rem;
  border: 1px solid #000000;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: 22%;
  transition: border-color 0.5s ease;
}

.quiz-container label .circle::before {
  content: "";
  height: 0.8rem;
  width: 0.8rem;
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  box-sizing: border-box;
  margin-left: 1px;
  margin-top: 1px;
  // margin-left:10px;
  // margin-top:10px;
  transition: transform 0.5s ease;
  transform: scale(0);
}
.quiz-container label input:checked + .circle::before {
  transform: scale(1);
}
.quiz-question {
  font-size: 1.3rem;
}
.quiz-button {
  width: 100%;
}
.quiz-countdown {
  font-size: 1.2rem;
}

.quiz-profile {
  .form-check + label::before {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  .form-check:checked + label::before {
    background: transparent url("~/public/assets/img/icons/radio-active.svg")
      no-repeat center center;
    border: unset;
    background-size: 100% 100%;
  }
  .quiz-title {
    font-size: 1.6rem;
    line-height: 2rem;
    padding-bottom: 2rem;
  }
  .quiz-question {
    font-size: 1.2rem;
    line-height: 1.6rem;
    display: block;
    padding-bottom: 0.5rem;
  }
  .form-check + label.small {
    font-weight: normal;
  }
  .owl-item {
    padding-left: 2px;
    padding-right: 2px;
  }
  .owl-theme .owl-nav [class*="owl-"]:hover {
    background: transparent;
  }
  .owl-theme .owl-nav,
  .next-prev-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    button {
      padding: 0 !important;
      background: transparent !important;
      img {
        max-width: 2.2rem;
      }
    }
  }
  .text-danger {
    position: absolute;
    left: 0;
    bottom: 3rem;
  }
  .ticked {
    margin-left: 0.5rem;
    margin-top: 4px;
  }
  .pagination {
    font-size: 1.2rem;
    line-height: 1;
    span {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
@media (min-width: 768px) {
  .quiz-profile {
    .form-check + label::before {
      width: 1.6rem;
      height: 1.6rem;
    }
    .quiz-title {
      font-size: 2.8rem;
      line-height: 1;
      padding-bottom: 3.5rem;
    }
    .quiz-question {
      font-size: 2.4rem;
      line-height: 1;
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
      padding-bottom: 1.5rem;
    }
    .quiz-container label {
      padding-left: 2.6rem;
    }
    .form-check + label.small {
      font-size: 2rem;
    }
    .owl-theme .owl-nav,
    .next-prev-btn {
      button {
        img {
          max-width: unset;
        }
      }
    }
    .text-danger {
      bottom: 6rem;
    }
    .ticked {
      width: 1.7rem !important;
    }
    .pagination {
      font-size: 2.4rem;
    }
    .btn-border {
      font-size: 2rem;
    }
  }
}
