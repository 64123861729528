.page {
  &-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 1.6rem 1.6rem 2rem;
    background-color: $light;
    z-index: 3;
    min-height: 8rem;
    &-logo {
      min-width: 5.2rem;
      &-md {
        min-width: 6.4rem;
      }
    }
    &-bottom {
      padding-top: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @media (min-width: 992px) {
      &-logo {
        min-width: 7.7rem;
        &-md {
          min-width: 7.7rem;
        }
      }
    }
  }
}
