.timer {
  display: flex;
  strong,
  small {
    color: $light;
    text-align: center;
  }
  strong {
    font-size: 1rem;
    line-height: 1;
    // padding-right:2px;
    // padding-left:2px;
  }
  small {
    font-size: 0.5rem;
    line-height: 1;
    display: block;
    margin-top: 0.5rem;
  }
  &-block {
    background-color: $dark;
    padding: 1.5rem;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $light;
      text-align: left;
      margin-bottom: 0;
    }
  }
}
.timer-box {
  padding: 1px 5px 3px;
  background-color: #e41e2b;
  width: 1.8rem;
}
@media (min-width: 768px) {
  .timer {
    &-box {
      width: 2.5rem;
    }
    strong {
      font-size: 2rem;
    }
  }
}

.countdown-seperate {
  position: relative;
  top: 40%;
}

@media (max-width: 800px) {
  .countdown-part h4 {
    font-size: 0.7rem;
  }
  .timer-box {
    padding: 5px;
  }
  .countdown-text h1 {
    font-size: 1rem;
  }
}

.margin-gap {
  margin-right: 3px;
}
.timer-box-margin {
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 2px;
}
