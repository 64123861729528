.capture-image {
  display: block;
  position: absolute;
  transform: translate(0%, 0%);
}
.refer-popup {
  .btn-refer {
    display: inline-block;
    width: auto;
  }
}
.modal {
  &-label {
    color: $secondary;
    font-size: 12px !important;
    margin-bottom: 10px;
  }
  &-response {
    padding: 1rem 7rem 2.6rem 7rem;
    margin-top: -38px;
    .btn {
      padding: 15px 40px;
      font-size: 1.2rem;
    }
  }
  &-address {
    h3 {
      padding-top: 25px;
    }
  }
  &-header {
    padding: 0;
    .close {
      padding: 2.2rem;
      margin: 0 0 0 auto;
      z-index: 2;
      position: relative;
      .icon-Cross-01 {
        font-weight: 900;
        font-size: 20px;
      }
    }
  }
  &-content {
    border-radius: 1.2rem;
  }
  &-unique-code {
    padding: 0 7rem 2.5rem 7rem;
    label {
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
      display: block;
    }
    input {
      background-color: $grey;
      &:focus {
        background-color: $grey;
      }
    }
  }
  &-code {
    text-align: center;
  }
  &-heading {
    font-size: 2.8rem;
    font-weight: 600;
  }
  &-description {
    font-size: 2.4rem;
  }
  &-search {
    width: 17px;
    margin-right: 5px;
    margin-top: -2px;
  }
  &-notification {
    font-size: 1.4rem;
    color: $secondary;
    span {
      color: $danger;
      font-weight: 700;
    }
  }
  &-video-wrapper {
    .modal {
      &-body {
        height: 60vh;
        .react-player {
          margin-top: -15px;
          width: auto !important;
          height: calc(100% - 35px) !important;
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    &-heading {
      font-size: 2rem;
    }
    &-unique-code {
      padding: 0 2rem 2.5rem 2rem;
      img {
        width: 78px;
      }
    }
    &-response {
      padding: 1rem 1.5rem 2rem 1.5rem;
      margin-top: -28px;
    }
    &-address {
      h3 {
        padding-top: 20px;
      }
    }
    &-description {
      font-size: 1.6rem;
    }
    &-notification {
      font-size: 1rem;
    }
    &-header {
      .close {
        padding: 1.2rem;
        .icon-Cross-01 {
          font-size: 12px;
        }
      }
    }
    &-icon {
      width: 50px;
    }
    &-block-image {
      width: 150px;
    }
  }
}

@media (min-width: 767.98px) {
  .modal {
    &-md {
      max-width: 696px;
    }

    &-video-wrapper {
      .modal {
        &-body {
          video {
            margin-top: -5px;
          }
        }
      }
    }
  }
}
