.form {
  &-address {
    .form-group {
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $dark;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: $dark;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: $dark;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: $dark;
      }
    }
  }
  &-refer {
    display: flex;
    align-items: center;
    padding: 0 1rem 0 0;
    position: relative;
    &-btn {
      text-wrap: nowrap;
    }
    .text-danger {
      position: absolute;
      left: 0;
      top: 100%;
    }
  }
  &-add-btn {
    position: relative;
    float: right;
    font-size: 1.4rem;
  }
  &-group {
    margin-bottom: 2.4rem;
    position: relative;
    label {
      font-size: 1.4rem;
      line-height: 1;
      margin-bottom: 1.2rem;
      font-weight: normal;
    }
    & > .text-danger {
      font-size: 1rem;
      line-height: 1.4rem;
      margin-top: 0.3rem;
      position: absolute;
      top: 100%;
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
  &-control {
    line-height: 1.6;
    min-height: 1.6rem;
    &:disabled {
      opacity: 0.4;
    }
  }
  &-square {
    flex: 1;
    margin: 5px;
    padding: 1rem;
    max-width: 4.5rem;
    height: 4.5rem;
    border-radius: 0.4rem;
    border: 0;
    line-height: 1;
  }
  &-select {
    border: 1px solid #838383;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    position: relative;
    font-size: 1.6rem;
    padding: 0.675em 2.2rem 0.675em 1em;
    background: $light url('~/public/assets/img/icons/select-icon.svg')
      no-repeat calc(100% - 7px) center;
    background-size: 1.2rem;
    color: $dark;
    cursor: pointer;
    &:focus-visible {
      outline: unset;
    }
    &-border {
      background-color: transparent;
      border: 1px solid $secondary;
      background-position: calc(100% - 10px);
      padding: 1rem 2.3rem 1rem 1rem;
      color: $dark;
      border-radius: 0.8rem;
      font-size: 1.2rem;
    }
    &-language {
      font-size: 1.2rem;
      padding: 0.2rem 0.2rem 0.2rem 0.5em;
      padding-right: 2.2rem;
      background-size: 0.8rem;
    }
  }
  &-custom-select {
    display: block;
    width: 100%;
    height: calc(1em + 3.4rem + 2px);
    padding: 0 3.2rem 0 1.7rem;
    background-position-x: calc(100% - 24px);
    font-family: 'TCCC-UnityText';
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 3.6;
    color: #000000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ffffff;
    border-radius: 2.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  &-otp {
    .input-group {
      justify-content: center;
    }
  }
  &-refer {
    input {
      background-color: $grey;
    }
    .btn {
      // padding: 0.8rem 1rem;
      white-space: nowrap;
    }
  }
  &-footer {
    text-align: center;
  }
  &-check {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    & + label {
      position: relative;
      padding-left: 2.3rem;
      margin-bottom: 0;

      &::before {
        content: '';
        border-radius: 2px;
        border: 1px solid #676767;
        width: 15px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        left: 0;
        top: 2px;
      }
      &.small {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
    &:checked {
      & + label {
        &::before {
          border-color: $dark;
          background: $dark url('~/public/assets/img/icons/check.svg') no-repeat
            center center;
        }
      }
    }
  }
  &-date {
    position: relative;
    line-height: 1.6rem;
    text-align: left;
    min-height: 1.6rem;
    padding-right: 3rem !important;
    background-image: url('~/public/assets/img/icons/calendar.svg') !important;
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
    background-size: 18px 20px;
    &::-webkit-calendar-picker-indicator {
      color: transparent;
      background: none;
      z-index: 1;
    }
    &:before {
      content: '';
      display: block;
      width: 15px;
      height: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 17px;
      background: url('~/public/assets/img/icons/calendar.svg') no-repeat center
        center;
      background-size: 100%;
    }
  }
  .profile-info-card {
    top: 2rem;
    right: 1rem;
  }
  @media (min-width: 576px) {
    &-wrapper {
      background-color: $light;
      min-height: 70vh;
    }
    &-invert {
      input[type] {
        background-color: $grey;
        border-color: $grey;
        &::focus {
          background-color: $grey;
        }
      }
    }
  }

  @media (min-width: 768px) {
    &-group {
      margin-bottom: 3rem;
      & > .text-danger {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
      label {
        font-size: 1.6rem;
        margin-bottom: 1.4rem;
        &.small {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }
    &-select {
      &-border {
        padding: 1rem 3rem 1rem 2rem;
        font-size: 1.6rem;
      }
    }
    &-refer {
      label {
        text-wrap: nowrap;
        line-height: 1;
        margin: 0 1rem 0 0;
      }
      .btn {
        padding: 0.8rem 2rem;
        margin-left: 2rem;
      }
    }
  }
  @media (min-width: 992px) {
    &-select {
      &-language {
        font-size: 1.6rem;
        border: unset;
        padding-left: 0;
        background-position-x: calc(100%);
        background-size: 1.2rem;
      }
    }
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  padding-right: 0 !important;
  background-image: none !important;
}
.i-icon {
  width: 1.5rem;
}
.tooltip-inner {
  background: #ffffff;
  color: #838383;
  border-radius: 5px;
  padding: 8px 12px;
  opacity: 1;
}
.custom-date-picker {
  .react-datepicker__close-icon {
    right: 3.2rem;
    &::after {
      background-color: $dark;
    }
  }
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: url('~/public/assets/img/icons/calendar.svg') !important;
    background-size: 18px 20px;
  }
}
