.nav {
  &bar {
    &-brand {
      margin: 0;
      margin-right: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .logo {
        max-width: unset;
        min-width: 5.2rem;
      }
    }
    &-toggler {
      padding: 0;
      margin: 0;
      border: none;
      &.collapsed {
        .navbar-toggler-icon {
          background-image: url('~/public/assets/img/icons/hamburger.svg');
          background-size: 2.6rem;
        }
      }
      &-icon {
        background-image: url('~/public/assets/img/icons/cross.svg');
        background-size: 2.6rem;
      }
    }
    &-left {
      .dropdown-toggle {
        padding-right: 2rem !important;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 1.5rem;
      .dropdown-toggle {
        padding-right: 0 !important;
        &::after {
          display: none;
        }
      }
      .dropdown-menu {
        margin-top: 2rem;
        &-end {
          right: -1rem;
        }
        &::after {
          left: auto;
          right: 2rem;
        }
      }
      .text-danger {
        position: absolute;
        left: 0;
        top: 100%;
      }
    }
  }
  &-item {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    line-height: 1;
  }
  &-link {
    font-size: 1.4rem;
    line-height: 1;
    color: $dark;
    position: relative;
    display: flex;
    padding: 0;
    align-items: center;
    .icon {
      margin-right: 1rem;
      width: 1.5rem;
    }
    &:hover {
      color: initial;
    }
  }
}

@media (min-width: 992px) {
  .nav {
    &bar {
      &-left {
        gap: 2vw;
      }
      &-right {
        gap: 3vw;
      }
      &-brand {
        margin-right: 4vw;
        position: static;
        transform: unset;
        .logo {
          max-width: unset;
          min-width: 7.7rem;
        }
      }
    }
    &-item {
      margin: 0;
    }
    &-link {
      font-size: 1.6rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}

@media (min-width: 1060px) {
  .nav {
    &bar {
      &-left {
        gap: 5rem;
      }
      &-right {
        gap: 3.4rem;
      }
      &-brand {
        margin-right: 6rem;
      }
    }
  }
}
