.ghknmB {
  display: none !important;
}
.tour-text {
  color: #000;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tour-btn-container {
  margin-top: 0.5rem;
  display: flex;
  justify-content: right;
  div {
    margin-right: 1.12rem;
  }
  .tour-btn-link {
    color: #1985d3;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // text-decoration-line: underline;
    cursor: pointer;
  }
}

.reactour__helper {
  padding: 1rem !important;
}

@media (min-width: 768px) {
  .reactour__helper {
    padding: 1.5rem !important;
  }
  .tour-text {
    font-size: 1.75rem;
  }
  .tour-btn-container {
    .tour-btn-link {
      font-size: 1.75rem;
    }
  }
}
