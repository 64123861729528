.btn {
  &-underline {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &-wrapper {
    justify-content: space-evenly;
  }
  &-contest {
    padding: 0.8rem 3rem 0.8rem 0.8rem !important;
    width: 150px;
    white-space: nowrap;

    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 12px;
    }
  }
  &-link {
    font-size: 1rem;
    font-weight: normal;
    &-lg {
      font-size: 1.4rem;
      line-height: 1;
    }
  }
  &-sm {
    padding: 0.8rem 1.5rem;
    font-size: 0.8rem;
    border-radius: 2rem;
  }
  &-md {
    padding: 1.2rem 3.5rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1;
  }
  &-lg {
    padding: 2rem 1.6rem 2.2rem;
  }
  &-record {
    width: 6.7rem;
    height: 6.7rem;
    //border: 2px solid $light;
    //border-radius: 100%;
    //position: relative;
    //background-color: transparent;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   width: 5.6rem;
    //   height: 5.6rem;
    //   background-color: $danger;
    //   border-radius: 100%;
    //   transform: translate(-50%, -50%);
    // }
  }
  &-ex-lg {
    padding: 1.4rem 6rem;
  }
  &-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      width: 2.4rem;
      height: 2.4rem;
      background-color: $light;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-claim {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 500;
    .icon {
      width: 1.2rem;
      margin-right: 0.5rem;
    }
  }
  &-border {
    border-width: 1px;
    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }
}
@media (min-width: 768px) {
  .btn {
    &-link {
      font-size: 1.6rem;
      font-weight: 500;
    }
    &-sm {
      padding: 1.6rem 1.6rem;
      font-size: 1.6rem;
      border-radius: 4.8rem;
    }
    &-ex-lg {
      padding: 1.8rem 13.8rem;
    }
    &-xl {
      padding: 1.8rem 13.8rem;
      font-size: 2.2rem;
    }
    &-wrapper {
      width: 900px;
    }
    &-contest {
      padding: 1.2rem 3rem 1.2rem 0.8rem !important;
      white-space: nowrap;
      width: 220px;
      img {
        width: 27px;
      }
      span {
        font-size: 16px;
      }
    }
    &-claim {
      font-size: 2rem;
      line-height: 1;
      .icon {
        width: 2rem;
      }
    }
  }
}
