.ta_global {
  .unique-code-wrapper input {
    padding-right: 55px;
  }
  #dropdown-user + .dropdown-menu-end {
    min-width: 21.5rem;
  }
  .offcanvas-menu {
    transform: translateX(-130%);
  }
  .offcanvas-menu.show {
    transform: translateX(0);
  }
  // Profile css start here
  .profile-footer p {
    white-space: nowrap;
  }
  .profile-footer p {
    font-size: 0.7rem;
  }
}

@media (max-width: 767.98px) {
  .ta_global {
    .footer p {
      font-size: 1.5vw;
    }
    .s-header .container-fluid{padding: 0 13rem 0 2rem;}
  }
}

@media (max-width: 384px) {
  .ta_global {
    #refer_friend {
      .refer-btns {
        padding: 1rem;
      }
    }
    .progress-prize {
      font-size: calc(4px + 1vw);
    }
    .footer .link,
    .profile-footer p {
      font-size: 7px;
    }
  }
}

@media (min-width: 768px) {
  .ta_global {
    .profile-footer p {
      font-size: 1.2rem;
    }
  }
}
