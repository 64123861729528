.ugc-page{
  .img{
    &-block,&-card{
        border-radius: 0;
    }
  }
  @media (min-width:767.98px) {
    .img{
        &-block,&-card{
            border-radius:2rem;
        }
      }
    }
}
.reels{
// width: 818.822px;
//   height: 1053.355px;
//   position: relative;
  &-profile,&-icons{
    position: absolute;
    bottom: 10px;
  }
  &-back{
    color: #fff;
    font-size: 30px;
    position: absolute;
    top: 30px;
    left: 45px;
  }
  &-profile{

font-size: 20px;
color: #fff;
margin-left: 15px;
  }
  &-icons{
   text-align: center;
    right: 0;
    .icon,p{
     all: unset;
      color: #fff;
     &-num{
      font-size: 16px;
     }
     &-like,&-view{
      font-size: 30px;
     }
    }
 
   }
  &-view{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}

.reels{
  &-profile,&-icons{
      position: absolute;
      bottom: 10px;
    }
    &-back{
      color: #fff;
      font-size: 30px;
      position: absolute;
      top: 30px;
      left: 35px;
    }
    &-profile{
  
  font-size: 20px;
  color: #fff;
  margin-left: 15px;
    }
    &-icons{
     text-align: center;
      right: 25px;
      .icon,p{
       all: unset;
        color: #fff;
       &-num{
        font-size: 16px;
       }
       &-like,&-view{
        font-size: 30px;
       }
       &.active {
        color: $danger;
        .icon-num {
          color: $light;
        }
      }
      }
   
     }
    &-view{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  
    iframe {
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    
  }
  
  }
  .video{
    &-container{
      position: relative;
      // height: 1050px;
      height: 78vh;
      width: 100%;
      max-width: 580px;
      margin: 0 auto;
      border-radius: 20px;
      background-color: white;
      overflow: scroll;
      scroll-snap-type: y mandatory;
      scrollbar-width: none;
      &::after{
        content: "";
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 1.33%, #000 127.95%);
        height: 300px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
     }
     &::-webkit-scrollbar {
      display: none;
     }
    }
    &-player{
      height: 100%;
    width: 100%; 
    }
    &-card{
       position: relative;
    height: 100%;
    margin-bottom: 20px;
    }

  }
  @media (max-width:767.98px) {
    .reels{
     &-back{
      left: 15px;
     }
    }
    .video{
      &-container{
      border-radius: 0;
    }
    }
    
  }