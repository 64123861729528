.header {
  background-color: $light;
  padding: 3.3rem 0;
  color: $light;
  position: fixed;
  z-index: 3;
  width: 100%;
  top: 0;
  &-profile-pic {
    width: 4.2rem;
    height: 4.2rem;
    display: block;
    img {
      width: 100%;
      border-radius: 100%;
    }
  }
  &-ugc {
    background-color: #fff;
    .col:first-child {
      display: none;
    }
  }
}

.search-wrapper {
  width: 100%;
  .search {
    width: 100%;
    input {
      border-radius: 8px;
      border: 1px solid #cdcdcd;
      padding: 7px 15px 7px 35px;
      font-size: 1.2rem;
      width: 100%;
    }

    &-img {
      position: absolute;
      top: 7px;
      left: 7px;
      width: auto;
    }
  }

  button {
    background-color: #fff;
  }
}

.notification-count {
  min-width: 0.7rem;
  min-height: 1.2rem;
  padding: 0 0.5rem;
  font-size: 6px;
  text-align: center;
  color: $light;
  line-height: 1.2rem;
  border-radius: 0.6rem;
  background-color: $danger;
  border-radius: 100%;
  position: absolute;
  right: 0;
  top: 0;
  font-family: 'TCCC-UnityText';
}
#dropdown-notification + .dropdown-menu-end {
  max-width: 62.4rem;
}
#dropdown-user + .dropdown-menu-end {
  min-width: 18.5rem;
  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      font-size: 2rem;
    }
  }
}
.dropdown-menu-end {
  left: auto;
  right: 0;
}

.unique-code-wrapper {
  border: 2px solid $dark;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px;

  input {
    font-size: 1.2rem;
    border: none;
    padding-right: 25px;
    background: transparent;
    color: $secondary;
    flex: 1;

    &:focus-visible {
      outline: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .btn {
    border-radius: 10rem;
    margin-left: -40px;
    padding: 1rem 1.6rem;
  }
}
@media (min-width: 768px) {
  .notification-count {
    min-width: 0.9rem;
    min-height: 1.4rem;
    border-radius: 0.7rem;
    font-size: 8px;
    line-height: 1.4rem;
  }
  .header {
    &-ugc {
      background-color: transparent;
      .col:first-child {
        display: block;
      }
      .s-header {
        text-align: left;
      }
      .search-wrapper {
        width: auto;
        .search {
          width: auto;
          input {
            padding: 13px 15px 13px 35px;
            width: 415px;
          }
          &-img {
            top: 12px;
          }
        }

        button {
          background-color: transparent;
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .header {
    padding: 1rem 0;
  }
}

.css-1p3m7a8-multiValue {
  display: none !important;
}
