.recorder {
  background-color: $dark;
  &-video {
    position: relative;
  }
  &-button {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3rem;
  }
}
