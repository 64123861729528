.footer {
  padding: 14px 0;
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  .unique-code {
    position: fixed;
    bottom: 60px;
    width: 65px;
  }
  &-auth {
    background-color: $dark;
    position: fixed;
    bottom: 0;
    padding-bottom: 2rem;
    width: 100%;
    a.link {
      font-size: 8px;
    }
    a.link,
    p {
      color: $light !important;
    }
  }
  .link {
    color: $light;
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 2px;
    border-bottom: 2px solid transparent;
    &:hover,
    &.active {
      border-bottom: 2px solid #df3025;
      border-radius: 4px 4px 0px 0px;
      color: #df3025;
      .footer-icon {
        filter: brightness(0) saturate(100%) invert(13%) sepia(98%)
          saturate(5768%) hue-rotate(358deg) brightness(97%) contrast(114%);
      }
      .count {
        background-color: $light;
        color: $dark;
      }
    }
    &-hover-disable {
      &:hover {
        border-bottom-color: transparent;
        color: $light;
      }
    }
  }
  .dietary-icon {
    width: 5px;
    height: 5px;
    vertical-align: top;
    margin-left: 0.5rem;
  }
  p {
    font-size: 0.98vw;
    color: $light;
  }
  .text-link {
    color: #9b9b9b;
    &:hover {
      color: #9b9b9b;
      text-decoration: none;
    }
  }
  &-claim-form {
    // background: #F1F2F1;
    // padding: 20px 15px ;
    .link,
    p {
      color: $dark;
    }
    .link {
      // font-size: 2.71vw;
      font-size: 1.4vw;
    }
    p {
      font-size: 4px;
    }
  }
  @media (min-width: 768px) {
    &-claim-form {
      .link {
        font-size: 1.5vw;
      }
    }
  }
  @media (min-width: 992px) {
    &-auth {
      position: absolute;
      bottom: 0;
      a.link {
        font-size: 1rem;
      }
      p {
        font-size: 6px;
      }
      a.link,
      p {
        color: $light !important;
      }
    }
    padding: 3.3rem 1.5rem;
    position: static;
    .dietary-icon {
      width: 15px;
      height: 15px;
    }
    .logo {
      width: 97px;
    }
    li {
      font-size: 1.4rem;
    }

    p {
      font-size: 1rem;
    }
  }
}
.count {
  font-size: 7px;
  font-weight: bold;
  line-height: 10px;
  color: $dark;
  background-color: $light;
  border-radius: 8px;
  padding: 0 3px;
  position: absolute;
  top: -4px;
  left: 50%;
  min-width: 2rem;
  text-align: center;
  transform: translateX(-20%);
}

.coin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transform: translate(-50%, -50%) scale(0);
  z-index: 999999;
  background-color: $danger;
  width: 300px;
  height: 300px;
}

@keyframes zoomInOut {
  0% {
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0);
  }
}

.moveCoin {
  animation: zoomInOut 1.5s forwards; /* Removed 'transform 1s forwards' */
}

@media (max-width: 340px) {
  .footer .link {
    font-size: 9px;
  }
}
