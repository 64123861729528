.karaoke {
  // margin-bottom: 10px;
  // max-width: 30rem;;
  .row {
    margin: 0;
  }
  .card {
    &-img {
      max-width: 10rem;
    }
    &-title {
      h2 {
        font-weight: 700;
      }
    }
    &-text {
      h4 {
        font-weight: 400;
      }
    }
  }
  .sec {
    &-1 {
      border-bottom: 1px solid #e1e1e1;
      button {
        background-color: transparent;
        img {
          width: 80%;
        }
      }
    }
    &-2 {
      button {
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 100%;
        border-radius: 0;
      }
      .videoButton {
        border-right: 1px solid #e1e1e1;
      }
    }
  }
}

.countdown {
  position: absolute;
  background-color: $dark;
  max-width: 6rem;
  width: 100%;
  border-radius: 47%;
  padding: 13px;
  display: flex;
  justify-content: center;
  top: 5%;
  left: 50%;
  &-text {
    color: $light;
  }
}
