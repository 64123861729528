[data-popper-placement='bottom-end'],
[data-popper-placement='bottom-start'] {
  .popover-arrow {
    background: $grey-2;
    width: 10px;
    height: 10px;
    transform: rotate(45deg) !important;
    bottom: calc(100% - 4px);
    left: auto !important;
    right: 5px;
    &:after {
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      width: 10px;
      height: 10px;
      background: #fff;
    }
  }
}
[data-popper-placement='bottom-start'] {
  .popover-arrow {
    right: auto;
    left: 5px !important;
  }
}
.popover {
  max-width: 85%;
  padding: 0;
  border: 1px solid $grey-2;
  color: #838383;
  font-size: 0.8rem;
  color: $dark;
  line-height: 1rem;
  background-color: $light;
  border-radius: 4px;
  padding: 5px;
  text-align: left;
  &-header {
    background-color: unset;
    border: unset;
    padding: unset;
    padding-top: 2.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
  }
  &-arrow {
    position: absolute;
  }
  @media (min-width: 767.98px) {
    max-width: 45%;
    font-size: 1rem;
    line-height: 1.2rem;
    color: $dark;
    &-header {
      .title {
        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: 500;
      }
    }
  }
}

.tooltip-right {
  .popover {
    max-width: 60%;
  }
}
