.avatar {
  &-wrapper {
    padding-left: 2rem;
    position: relative;
    display: inline-block;
    .avatar-edit {
      bottom: 0;
      right: 0;
      position: absolute;
    }
    & + .dropdown {
      &-menu {
        border-radius: 5px;
        width: 200px;
        border: 1px solid $grey-2;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
        margin-top: 0;
        padding: 1rem;
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        img {
          margin: 0.2rem;
          max-width: 37px;
        }
        &:after {
          left: 50%;
          transform: translateX(-50%);
          display: none;
        }
        .dropdown-item {
          color: $dark;
          font-size: 1.6rem !important;
          padding: 1.2rem;
          border-bottom: 1px solid $grey;
          &:last-child {
            border: unset;
          }
        }
      }
      &-menu:not(.show) {
        display: none;
      }
    }
  }
  &-img {
    width: 5.3rem;
    height: auto;
    border-radius: 100%;
  }
  &-select-wrapper {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 66%;
    max-width: 190px;
    border-radius: 1rem;
    padding: 1rem;
    z-index: 2;
    background-color: $light;
    border: 1px solid $grey;
    img {
      margin: 0.2rem;
      max-width: 3.7rem;
    }
  }
}

.profile {
  &-info-header {
    background-color: $dark;
    border-radius: 0.5rem;
    padding: 1rem 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-coin {
      max-width: 3rem;
      margin-right: 0.5rem;
    }
    .title {
      font-size: 1.4rem;
      color: $light;
      line-height: 1;
      padding-left: 0.5rem;
    }
    .btn-link {
      color: $light;
      font-size: 1.2rem;
      font-weight: normal;
      display: flex;
      align-items: center;
      .icon-Right-arrow-01 {
        font-weight: bold;
        padding-top: 3px;
      }
      &:hover {
        color: $light;
      }
    }
  }
  &-img {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
  }
  &-title {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  &-text {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 0.2rem;
  }
  &-card {
    padding: 1.5rem 0;
    .icon-edit-01 {
      color: $dark;
      font-size: 1.5rem;
      line-height: 1;
      &:hover {
        color: $dark;
      }
    }
  }
  &-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &-detail {
    padding: 0 1.5rem;
  }
  &-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-footer {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0 0;
    width: 8rem;
    p {
      margin: 0;
      font-size: 0.8rem;
      position: relative;
      .icon-info {
        position: absolute;
        padding-left: 0.5rem;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &-progress-bar {
    max-width: 7.5rem;
    min-width: 7.5rem;
  }
}

.address {
  &-card {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    background-color: $light;
    border: 1px solid $grey-2;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
  &-left {
    p {
      font-size: 1.6rem;
      line-height: 1.25;
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .icon {
      font-size: 1.8rem;
      color: $dark;
      &:disabled {
        opacity: 0.4;
      }
      &:hover {
        color: $dark;
      }
    }
  }
  &-full {
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $grey-2;
    margin-bottom: 0;
    &:last-child {
      border: unset;
      padding-bottom: 0;
    }
    .address {
      &-left {
        p {
          font-size: 1.4rem;
        }
      }
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-link {
      border: unset;
      background-color: transparent;
      color: $link-color;
      padding-right: 0;
      font-weight: normal;
      &:disabled {
        color: $grey-2;
        opacity: 1 !important;
      }
    }
    &-lg {
      .address {
        &-title {
          // font-size: 1.2rem;
          // line-height: 1.4rem;
          font-size: 1.4rem;
          line-height: 1.4rem;
          font-weight: 500;
        }
      }
      .btn-link {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
  }
  &-title {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: normal;
  }
}

.data {
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    &-item {
      border-radius: 5px;
      border: 1px solid #cdcdcd;
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      &-status {
        display: flex;
        align-items: center;
      }
      .icon-Checked-1-01 {
        font-size: 1rem;
        margin-right: 0.4rem;
        color: $danger;
      }
      &:last-child {
        margin-bottom: unset;
      }
      p {
        font-size: 1.2rem;
        line-height: 1;
        margin: 0;
      }
      &.pending {
        .data-list-item-status {
          color: #b0b0b0;
        }
      }
      &.accept {
        border-color: #646464;
      }
    }
    &-avatar {
      max-width: 2.2rem;
      margin-right: 5px;
    }
    &-header {
      background-color: $dark;
      color: $light;
      p {
        line-height: 2.2rem;
      }
    }
  }
}

@media (min-width: 768px) {
  .profile {
    &-info-header {
      padding: 1.2rem 2.2rem;
      &-coin {
        width: 4.2rem;
      }
      .title {
        font-size: 2rem;
        padding-left: 1.5rem;
      }
      .btn-link {
        font-size: 1.6rem;
      }
    }
    &-img {
      width: calc(100% - 12px);
      height: calc(100% - 12px);
    }
    &-title {
      font-size: 2rem;
    }
    &-text {
      font-size: 2rem;
      line-height: 2.8rem;
      margin-bottom: 0;
    }
    &-card {
      padding: 4rem 0;
      .icon-edit-01 {
        font-size: 1.8rem;
      }
    }
    &-detail {
      padding: 0 2.8rem;
    }
    &-footer {
      width: 16.5rem;
      p {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
    &-progress-bar {
      max-width: 13.5rem;
    }
  }
  .address {
    &-full {
      padding-top: 4.3rem;
      padding-bottom: 4.3rem;
      border-width: 2px;
      .default-address-label {
        font-size: 2rem;
        line-height: 1;
      }
      .form-check + label {
        padding-left: 3rem;
        &::before {
          width: 2rem;
          height: 1.9rem;
        }
      }
      .address {
        &-left {
          p {
            font-size: 2rem;
            line-height: 2.8rem;
          }
        }
      }
    }
    &-header {
      &-lg {
        .address {
          &-title {
            // font-size: 2rem;
            font-size: 2.8rem;
            line-height: 1;
          }
        }
        .btn-link {
          font-size: 2rem;
          line-height: 1;
        }
      }
    }
  }
  .data {
    &-list {
      &-item {
        border-radius: 8px;
        border-width: 2px;
        padding: 1.8rem 3rem;
        margin-bottom: 16px;
        .icon-Checked-1-01 {
          font-size: 2rem;
          margin-right: 1rem;
        }
        &:last-child {
          margin-bottom: unset;
        }
        p {
          font-size: 2rem;
        }
      }
      &-header {
        p {
          line-height: 2.8rem;
        }
      }
      &-avatar {
        max-width: 2.8rem;
        margin-right: 1.5rem;
      }
    }
  }
}

.custom-table {
  tbody,
  thead {
    th,
    td {
      border: none;
      font-size: 1.2rem;
      line-height: 1.6rem;
      padding: 1rem 1.5rem;
    }
    th,
    td {
      margin-bottom: 0.8rem;
      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
        .avatar {
          max-width: 2.2rem;
          margin-right: 5px;
        }
      }
      &:last-child {
        border-radius: 0 0.5rem 0.5rem 0;
        text-align: right;
      }
    }
  }
  th {
    background: $dark;
    color: $light;
  }
  .pending {
    background-color: $light;
    td {
      &:first-child {
      }
    }
  }
  .accept {
    background-color: #f1f1f1;
    border: 1px solid #fff;
  }
}

.profile-table {
  width: -webkit-fill-available;
  border-collapse: separate;
  border-spacing: 0 1rem;
}
.refer-info {
  h3 {
    font-size: 1.4rem;
  }
  p {
    font-size: 1rem;
    color: #484848;
  }
  @media (min-width: 767.98px) {
    h3 {
      font-size: 2.8rem;
    }

    p {
      font-size: 2rem;
    }

    .custom-table {
      th,
      td {
        font-size: 1.6rem;
        padding: 1.4rem;
      }
    }
  }
}

.my-profile {
  // background-color: white;
  .edit-icon {
    position: absolute;
    top: 19px;
    right: 0;
    padding-right: 1.5rem;
    width: 2.9rem;
    @media (min-width: 768px) {
      // right: 2rem;
    }
  }
  .moredetailtext {
    font-weight: 10;
    text-align: center;
  }
  .s-sub-title {
    color: #484848;
  }
  .profile-mobile-sign {
    min-width: 1.5rem;
  }
  .profile-address-sign {
    min-width: 1.8rem;
    align-self: self-start;
    margin-top: 0.2rem;
  }
  .mobile-no-info {
    font-size: 1.5rem;
    padding-left: 1rem;
  }
  .address-tag {
    font-size: 1.3rem;
    font-weight: 10;
  }
  .default-address-sign {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1.8rem;
  }
  .default-address-div {
    width: 96vw;
    position: relative;
    left: 57%;
    right: 49%;
    margin-left: -51vw;
    padding: 1.2rem;
    border-radius: 1rem;

    @media (min-width: 445px) {
      width: 95vw;
    }
    @media (min-width: 575px) {
      width: 93vw;
      margin-left: -51vw;
    }
    @media (min-width: 768px) {
      width: 83vw;
      margin-left: -45vw;
    }
    @media (min-width: 925px) {
      width: 86vw;
      margin-left: -47vw;
    }
    @media (min-width: 1225px) {
      width: 89vw;
      margin-left: -49vw;
    }
    @media (min-width: 1468px) {
      width: 84.5vw;
      margin-left: -47vw;
    }
    @media (min-width: 1600px) {
      width: 77vw;
      margin-left: -43vw;
    }
  }
  .cs-coin-image {
    @media (min-width: 768px) {
      width: 3rem;
      margin-left: 1rem;
    }
  }
  .collect-more {
    cursor: pointer;
  }
  .collectmore-outer {
    @media (min-width: 768px) {
      padding: 0 5rem;
    }
  }
}

@media (max-width: 575.98px) {
  .coinstext {
    font-size: 1.7rem !important;
  }
}

.refer-now-btn {
  height: 4.75rem;
  padding: 1rem 1.8rem;
  white-space: nowrap;
}

.default-address-label {
  font-size: 1.3rem;
  color: #000;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}

.refer-btns {
  padding: 10px 24px;
  font-size: 1rem;
  text-wrap: nowrap;
  @media (min-width: 768px) {
    padding: 20px 40px;
    font-size: 2rem;
  }
}

.data {
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    &-header {
      background-color: $dark;
      color: $light;
    }
    &-item {
      border-radius: 5px;
      border: 1px solid #cdcdcd;
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      .icon-Checked-1-01 {
        font-size: 1rem;
        color: $success;
      }
      &:last-child {
        margin-bottom: unset;
      }
      p {
        font-size: 1.2rem;
        line-height: 1;
        margin: 0;
      }
      &.pending {
        .data-list-item-status {
          color: #b0b0b0;
        }
      }
    }
    &-avatar {
      max-width: 2.2rem;
      margin-right: 5px;
    }
  }
}

.profile-next-prv-btn{
  border: 1px solid #000;
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  @media (max-width: 768px) {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  }
}

.profile-q-btn{
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-top: 30px;
    }
}