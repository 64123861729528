.wallet {
  &-card {
    border: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-left: 10px;
  }
  &-header {
    border-radius: 0px !important;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: -2px;
  }
  &-body {
    padding: 1.5rem;
    padding-bottom: 25px;
  }
  &-img {
    width: 53.5px;
  }
  &-top-img {
    width: 53.5px;
  }
}

.coin {
  &-title {
    font-size: 1.4rem;
    line-height: 1;
    margin-bottom: 1.6rem;
  }
  &-num {
    font-size: 2.8rem;
    line-height: 1;
    margin-right: 5px;
  }
  &-icon {
    width: 2.5rem;
  }
}

.artist {
  &-name {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-top: 0.7rem;
  }
  &-img {
    margin: 0 auto;
  }
}

.bar-container {
  width: 20px;
  height: 200px;
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden; /* Make sure the child can't overflow the container */
}

.percentage-bar {
  width: 100%;
  background-color: #3498db;
  position: absolute;
  bottom: 0;
  transition: height 0.5s ease; /* Optional: Add a smooth transition effect */
}

// home changes

.tabs {
  display: flex;
  padding-bottom: 20px;
}

.tab {
  display: inline-block;
  position: relative;
  padding: 10px 10px;
  cursor: pointer;
  color: #333;
  text-decoration: none;
  margin-right: 15px;
}

.tab.active {
  color: red;

  h3 {
    color: red;
  }
}

.tab.active::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: red;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

//home artist chANGE

.underline {
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
  margin-top: 10px;
}

//progress Bar

.progress {
  &-wrapper {
    padding: 2rem 0;
    position: relative;
  }
  &-bar {
    width: calc(100% - 0.8rem);
    left: 0.4rem;
    height: 1.4rem;
    border-radius: 0.7rem;
    background-color: #cdcdcd;
    position: absolute;

    top: 65px;
  }
  &-percentage {
    height: 100%;
    width: 10%;
    background-color: $danger;
    border-radius: 0.7rem;
  }
  &-bullet {
    // display: flex;
    // justify-content: space-between;
    // list-style: none;
    &-list {
      position: relative;
      &:first-child {
        .progress-points {
          background-color: transparent;
          color: $dark;
          &::after {
            display: none;
          }
        }
      }
      &:first-child {
        .progress-indicator {
          transform: unset;
          left: -1px;
        }
        .progress-coin-icon {
          display: none;
        }
        .progress-points {
          bottom: -6px;
          padding-left: 3px;
          margin-bottom: 1rem;
        }
        .progress-circle {
          margin-left: 0;
        }
      }
      &:last-child {
        .progress-prize {
          position: relative;
          right: 3%;
        }
        .progress-points::after {
          left: 80%;
        }
        .progress-indicator {
          left: auto;
          right: 0;
          transform: unset;
          text-align: right;
        }
        .progress-circle {
          margin: 0 0 0 auto;
        }
        @media (max-width: 800px) {
          .progress-points::after {
            left: 70%;
          }
        }
      }
    }
  }
  &-circle {
    background-color: $light;
    width: 3.3rem;
    height: 3.3rem;
    border: 1rem solid $grey-2;
    border-radius: 100%;
    display: block;
    margin: 0 auto;
    &.active {
      border-color: $danger;
    }
  }
  &-indicator {
    text-align: center;
    position: absolute;
    left: 100%;
    transform: translateX(-50%);
  }
  &-prize {
    &.active {
      color: $danger;
    }
  }
  &-points {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $dark;
    padding: 0.4rem 1rem 0.5rem;

    color: $light;
    text-align: center;
    border-radius: 4px;
    position: relative;
    margin-bottom: 0.9rem;
    bottom: 5px;
    span {
      font-size: 1.7rem;
      line-height: 1;
    }
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $dark;
      position: absolute;
      left: 50%;
      top: 97%;
      transform: translateX(-50%);
    }
  }
}

.progress-coin-icon {
  max-width: 16px;
  margin-left: 0.3rem;
}

@media screen and (max-width: 400px) {
  .wallet {
    &-img {
      width: 30.5px;
    }
    &-top-img {
      width: 53.5px;
    }
  }
}
@media screen and (max-width: 550px) {
  .wallet {
    &-img {
      width: 40.5px;
    }
    &-top-img {
      width: 53.5px;
    }
  }
}
@media (max-width: 767.98px) {
  .progress {
    &-circle {
      width: 1rem;
      height: 1rem;
      border: 0.3rem solid $grey-2;
    }
    &-bar {
      height: 0.4rem;
      top: 59px;
    }
  }
}
@media (max-width: 800px) {
  .progress-points span {
    font-size: 1rem;
    line-height: 1;
  }

  .progress-bullet-list {
    &:first-child {
      .progress-points {
        bottom: -13px;
        margin-bottom: 1.7rem;
      }
    }
  }

  .progress-coin-icon {
    max-width: 8px;
    margin-left: 0.3rem;
  }
  .progress-points {
    margin-bottom: 1.7rem;
    bottom: -7px;
  }
}

@media (max-width: 1050px) {
  .progress-prize {
    font-size: calc(7px + 1vw);
  }
}

@media (min-width: 768px) {
  .artist {
    &-name {
      font-size: 25px;
      line-height: 30px;
      margin-top: 2rem;
    }
  }
  .coin {
    &-title {
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }
    &-num {
      font-size: 6rem;
      margin-right: 10px;
    }
    &-icon {
      width: 5rem;
    }
  }
  .wallet {
    &-top-img {
      width: 8.4rem;
    }
    &-header {
      padding: 4rem 0;
    }
  }
  .points-wallet {
    &-block {
      margin: 2.8rem 0;
    }
    &-title {
      font-size: 1.6rem;
      line-height: 1.8rem;
      padding-right: 1rem;
    }
    &-box {
      @include border-radius(2.6rem);
    }
    &-num {
      font-size: 1.5rem;
      line-height: 1;
      color: $secondary;
      padding: 0 1rem 0 2rem;
    }
    &-sign {
      width: 2.8rem;
    }
  }

  .progress {
    &-points {
      padding: 0.4rem 1.5rem 0.5rem;
    }
    &-bar {
      width: calc(100% - 4.8rem);
      left: 2.4rem;
    }
    &-bullet {
      // display: flex;
      // justify-content: space-between;
      // list-style: none;
      &-list {
        &:first-child {
          .progress-points {
            padding-left: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .progress-bullet-list:first-child .progress-points {
    bottom: -2.1rem;
    margin-left: -2px;
  }
}
