.pagination-container{
    cursor: pointer;
    font-size: 1.3rem;
}

.current-page{
    border-radius: 2px;
    background: #000;
    color: #FFF;
    text-align: center;
    width: 2.5rem;
}