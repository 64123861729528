.bannercard-outer {
  display: flex;
  border-radius: 1.3rem;
  background: $dark;
}

.points {
  &-bg {
    margin: 0px;
    margin-right: 0.88rem;
    width: 16.375rem;
    height: 11.975rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  &-get {
    color: #000;
    text-align: center;
    font-family: TCCC-UnityText;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 100;
    padding: 0;
    margin: 0;
    line-height: normal;
  }
  &-box-black {
    background-color: black;
  }
  &-black {
    color: white;
  }
}
.getpoints {
  // z-index: 9999;
  position: absolute;
}

.getupto-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.buycoke {
  &-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  &-header {
    color: #fff;
    font-family: TCCC-UnityText;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6rem;
    text-transform: uppercase;
    margin-bottom: 0.87rem;
  }
  &-btn {
    width: 6.4375rem;
    height: 2.5375rem;
    border: none;
    flex-shrink: 0;
    border-radius: 2.58519rem;
    background: #df3025;
    color: #fff;
    font-family: TCCC-UnityText;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.58569rem;
  }
  &-input {
    width: 9.83438rem;
    height: 2.36531rem;
    flex-shrink: 0;
    color: #9e9e9e;
    font-family: TCCC-UnityText;
    font-size: 0.86531rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.58569rem;
    border: none;
    background: #000;
  }
}

.submitcode-outer {
  display: flex;
}

.input {
  &-holder {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #aeaeae;
    margin-right: 1.29rem;
    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

.buy-info {
  color: #fff;
  font-family: TCCC-UnityText;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 142.857% */
  opacity: 0.8;
  margin-top: 0.95rem;
}

.coin-info-outer {
  display: block;
  padding: 1rem 1rem 1.3rem 1rem;
  border-radius: 0.4rem;
  background: white;
  color: $dark;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 468px) {
    min-height: 10rem;
  }
  &:hover {
    color: inherit;
  }
}

.title {
  &-div {
    display: flex;
    align-items: center;
    img {
      width: 4rem !important;
    }
    .main-coin-title {
      font-size: 1.1rem;
      line-height: 1.2rem;
      font-weight: 500;
      margin-left: 1rem;
      @media (max-width: 390px) {
        // font-size: 1.1rem;
        // line-height: 1.2rem;
        margin-left: 0.3rem;
      }
    }
    .sub-coin-title {
      font-size: 0.75rem;
      line-height: 1.1rem;
      font-weight: 10;
      margin-left: 1rem;
      @media (max-width: 390px) {
        font-size: 0.8rem;
        line-height: 1rem;
        margin-left: 0.3rem;
      }
    }
  }
}

.info {
  &-points {
    display: flex;
    align-items: center;
    margin-top: 0.9rem;
    // @media (max-width: 468px) {
    //   position: absolute;
    //   bottom: 1.3rem;
    // }
  }
  &-text {
    margin-left: 1.2rem;
    font-size: 1rem;
    line-height: 1.4rem;
    @media (max-width: 390px) {
      font-size: 0.8rem;
      line-height: 1rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .bannercard-outer {
    border-radius: 2rem;
  }
  .points-bg {
    width: 21.375rem;
    height: 17.975rem;
  }
  .buycoke {
    &-header {
      font-size: 2.15rem;
      width: 90%;
      font-weight: 100;
      line-height: 2.25rem;
      margin-bottom: 1.87rem;
    }
    &-input {
      width: 19.83438rem;
      height: 3.36531rem;
      font-size: 1.36531rem;
      font-weight: 100;
      line-height: 1.18569rem;
    }
    &-btn {
      width: 11.4375rem;
      height: 3.5375rem;
      font-size: 1.5rem;
      font-weight: 100;
    }
  }
  .input-holder img {
    width: 2.2rem;
    height: 2.2rem;
    margin-bottom: 5px;
  }
  .buy-info {
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.625rem;
    margin-top: 1.55rem;
  }
  .second-container {
    grid-template-columns: 25rem 25rem 25rem;
    grid-gap: 6.7rem;
    justify-content: center;
  }
  .coin-info-outer {
    // width: 26rem;
    padding: 2.3rem 2.3rem 3.5rem 2.3rem;
    border-radius: 0.9rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 14rem;
  }
  .title {
    &-div {
      align-items: flex-start;

      img {
        width: 6.8rem !important;
      }
      .main-coin-title {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: 1rem;
      }
      .sub-coin-title {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
  .info {
    &-card {
      &-pts {
        font-size: 1.1rem;
      }
      &-sign {
        width: 2.6rem;
      }
    }
    &-points {
      margin-top: 3.2rem;
    }
    &-text {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }
}

// Coke Coin Banner
.coke-card {
  background-color: $dark;
  border-radius: 1.3rem;
  display: flex;
  align-items: center;
  .btn {
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
    line-height: 1;
  }
  &-img {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    max-width: 112px;
    max-height: 100px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &-body {
    flex: 1;
    padding: 1rem;
    text-align: center;
    max-width: 74rem;
    margin: auto;
  }
  &-title {
    font-size: 0.8rem;
    line-height: 1.4;
    // text-transform: uppercase;
    color: $light;
    margin-bottom: 1rem;
  }
  &-sub-title {
    font-size: 0.7rem;
    line-height: 1.4;
    text-transform: uppercase;
    color: $light;
    margin-top: 1rem;
  }
  &-text {
    font-size: 0.6rem;
    line-height: 0.8rem;
    color: $light;
    margin-top: 1rem;
  }
}
@media (min-width: 768px) {
  .coke-card {
    border-radius: 2rem;
    .btn {
      font-size: 2rem;
      padding: 1.6rem 4rem;
    }
    &-img {
      max-width: 26.5%;
      max-height: 26.4rem;
    }
    &-body {
      flex: 1;
      padding: 4rem 2rem;
    }
    &-title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 3rem;
    }
    &-sub-title {
      font-size: 1.9rem;
      margin-top: 2rem;
    }
    &-text {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-top: 3rem;
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .coke-card {
    &-title {
      font-size: 2.82vw;
      line-height: 3.23vw;
    }
    &-text {
      font-size: 1.61vw;
      line-height: 2.02vw;
    }
  }
}
